import NotFound from "../views/NotFound.vue";

export default {
  path: "*",
  name: "NotFound",
  component: NotFound,
  meta: {
    title: "Pozos Manantial - 404",
    requiresGuest: false,
    requiresAuth: false,
  },
};
