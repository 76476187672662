<template>
  <div class="header-container">
    <h4 class="title">{{ $route.name }}</h4>
    <el-dropdown>
      <span class="el-dropdown-link"> <i class="el-icon-user-solid"></i><i class="el-icon-arrow-down el-icon--right"></i> </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="!isAuth">
          <router-link to="/auth/login">Login</router-link>
        </el-dropdown-item>
        <el-dropdown-item v-if="isAuth">
          <router-link to="/logout">Logout</router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { onLogout } from "../../vue-apollo.js";
export default {
  name: "Header",
  computed: {
    ...mapGetters({
      isAuth: "Auth/isAuth",
    }),
  },
  methods: {
    // logout() {
    //   onLogout(this.$apollo.provider.defaultClient);
    //   this.$router.push("/auth/login");
    // },
  },
};
</script>

<style>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
