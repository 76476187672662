<template>
  <div class="logo-container">
    <el-image class="logo" :fit="fits" :alt="alt" :src="url">
      <div slot="error" class="image-error">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <h3 class="title-logo" v-text="title">Pozos Manatial</h3>
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      fits: "cover",
      alt: "Pozos Manantial",
      title: "Pozos Manantial",
      url: require("@/assets/images/logo.png"),
    };
  },
};
</script>
