import { createApolloClient, restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";

import Vue from "vue";
import VueApollo from "vue-apollo";
import { setContext } from "apollo-link-context";

Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Urls GRAPHQL_HTTP
const URL_LOCAL = "http://127.0.0.1:8000/graphql";
const URL_DINAMIC = "http://api.seiconsultores.cl/graphql";

// Http endpoint
const httpEndpoint = process.env.NODE_ENV === "development" ? process.env.VUE_APP_GRAPHQL_HTTP || URL_LOCAL : process.env.VUE_APP_GRAPHQL_HTTP || URL_DINAMIC;
// const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || "http://api.seiconsultores.cl/graphql";

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("apollo-token");
  return {
    ...headers,
    Authorization: token || "",
  };
});

// Config
const defaultOptions = {
  httpEndpoint,
  wsEndpoint: null,
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: authLink,
  // Override default cache
  // cache: myCache
  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...
  // Additional ApolloClient options
  // apollo: { ... }
  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export const { apolloClient } = createApolloClient({
  ...defaultOptions,
});

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log("%cError", "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;", error.message);
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
