import AuthRoutes from "./Auth.js";
import NotFoundRoutes from "./NotFound.js";
import PublicRoutes from "./Public.js";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [AuthRoutes, PublicRoutes, NotFoundRoutes];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["Auth/isAuth"];
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    document.title = to.meta.title;
    if (!isLoggedIn) {
      store.dispatch("Auth/logoutUser");
      next({
        path: "/auth/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else if (to.matched.some(rec => rec.meta.requiresGuest)) {
    document.title = to.meta.title;
    if (isLoggedIn) {
      next({
        path: "/",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
