<template>
  <el-menu default-active="1" class="aside-menu">
    <!-- Menu Principal -->
    <router-link to="/">
      <el-menu-item index="1">
        <i class="el-icon-folder-opened"></i>
        Cotizaciones
      </el-menu-item>
    </router-link>
    <!-- Gurpo de Menus  -->
    <el-menu-item-group :title="menu.title" v-for="menu in menuData" :key="menu.id">
      <router-link :to="subMenu.link" v-for="subMenu in menu.subMenu" :key="subMenu.id">
        <el-menu-item :index="subMenu.index">
          <i :class="subMenu.icon"></i>
          {{ subMenu.title }}
        </el-menu-item>
      </router-link>
    </el-menu-item-group>
  </el-menu>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      menuData: [
        {
          title: "Mantenedores",
          subMenu: [
            {
              index: "6",
              link: "/valores",
              icon: "el-icon-coin",
              title: "Tarifas",
            },
            {
              index: "3",
              link: "/diametros",
              icon: "el-icon-c-scale-to-original",
              title: "Diametros",
            },
            {
              index: "4",
              link: "/productos",
              icon: "el-icon-shopping-cart-full",
              title: "Productos",
            },
            {
              index: "5",
              link: "/regiones",
              icon: "el-icon-school",
              title: "Regiones",
            },
            {
              index: "2",
              link: "/comunas",
              icon: "el-icon-school",
              title: "Comunas",
            },
          ],
        },
        // {
        //   title: "Cotización",
        //   subMenu: [
        //     {
        //       index: "7",
        //       link: "/cotizaciones",
        //       icon: "el-icon-folder-opened",
        //       title: "Cotizaciones",
        //     },
        //   ],
        // },
        {
          title: "Congifuración",
          subMenu: [
            {
              index: "8",
              link: "/usuarios",
              icon: "el-icon-user",
              title: "Usuarios",
            },
          ],
        },
      ],
    };
  },
};
</script>
