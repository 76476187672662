<template>
  <div>
    <div class="main">
      <div class="container">
        <el-card class="main-card">
          <router-view />
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
};
</script>
