<template>
  <div class="not-found d-flex">
    <div class="d-flex flex">
      <div class="d-flex flex background-error">
        <div class="container info-error">
          <h1 class="title-error maxWidth">Oops!</h1>
          <p class="sub-title-error maxWidth">Algo salió mal aquí.</p>
          <p class="desc-error maxWidth">Estamos trabajando en ello y lo arreglaremos lo antes posible. Puede volver a la pagina de <router-link to="/">inició</router-link>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
* {
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
}

.d-flex {
  display: flex;
  flex-flow: row wrap;
}

.flex {
  flex: 1 1 auto;
}

.maxWidth {
  max-width: 100%;
}

.not-found {
  height: 100vh;
  color: #3f4254;
  background: #eef0f8;
}

.background-error {
  background-image: url("../assets/images/not-found.jpg");
  background-size: cover;
  background-position: center;
}

.info-error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2.5rem;
}

.title-error {
  font-weight: 700;
  color: #8950fc;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  font-size: 5rem;
}

.sub-title-error {
  font-weight: 700;
  font-size: 1.6rem;
}

.desc-error {
  font-size: 1.1rem;
  word-break: break-word;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
    max-width: none;
  }

  .title-error {
    font-size: 7rem;
    margin-top: 0 !important;
    font-size: 12rem !important;
  }

  .sub-title-error {
    font-size: 2.5rem !important;
  }

  .desc-error {
    font-size: 1.3rem !important;
  }

  .info-error {
    justify-content: center;
    padding: 2rem;
  }

  .maxWidth {
    max-width: 75% !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}
</style>
