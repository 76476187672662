<template>
  <div class="container-user">
    <div class="main-user">
      <el-image class="logo-user" :fit="fits" :alt="alt" :src="url">
        <div slot="error" class="image-error">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <div class="info-user" v-if="$route.name === 'Login'">
        <h3>Iniciar sesión</h3>
        <div class="text-muted">Ingrese sus datos para iniciar sesión en su cuenta</div>
      </div>
      <div class="info-register" v-if="$route.name === 'Register'">
        <h3>Registro</h3>
        <div class="text-muted">Ingrese sus datos</div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fits: "contain",
      alt: "Pozos Manantial",
      url: require("@/assets/images/logo.png"),
    };
  },
};
</script>

<style>
</style>
