import LayoutView from "@/views/Layout.vue";
import store from "../store";

export default {
  path: "/",
  name: "Layout",
  component: LayoutView,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "",
      name: "Cotizaciones",
      component: () => import("../views/Cotizaciones.vue"),
      meta: { title: "Pozos Manantial - Cotizaciones" },
    },
    {
      path: "comunas",
      name: "Comunas",
      component: () => import("../views/Comunas.vue"),
      meta: { title: "Pozos Manantial - Comunas" },
    },
    // {
    //   path: "cotizaciones",
    //   name: "Cotizaciones",
    //   component: () => import("../views/Cotizaciones.vue"),
    //   meta: { title: "Pozos Manantial - Cotizaciones" },
    // },
    {
      path: "diametros",
      name: "Diametros",
      component: () => import("../views/Diametros.vue"),
      meta: { title: "Pozos Manantial - Diametros" },
    },
    {
      path: "productos",
      name: "Productos",
      component: () => import("../views/Productos.vue"),
      meta: { title: "Pozos Manantial - Productos" },
    },
    {
      path: "regiones",
      name: "Regiones",
      component: () => import("../views/Regiones.vue"),
      meta: { title: "Pozos Manantial - Regiones" },
    },
    {
      path: "usuarios",
      name: "Usuarios",
      component: () => import("../views/Usuarios.vue"),
      meta: { title: "Pozos Manantial - Usuarios" },
    },
    {
      path: "valores",
      name: "Tarifas por Región",
      component: () => import("../views/Valores.vue"),
      meta: { title: "Pozos Manantial - Valores" },
    },
    {
      path: "logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        store.dispatch("Auth/logoutUser");
        next("/auth/login");
      },
    },
  ],
};
