<template>
  <el-container class="container-app">
    <el-aside class="aside-app">
      <Logo />
      <Menu />
    </el-aside>

    <el-container>
      <el-header class="header-app">
        <Header />
      </el-header>

      <el-main class="content-app">
        <Main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header/Header";
import Logo from "@/components/Aside/Logo";
import Menu from "@/components/Aside/Menu";
import Main from "@/components/Content/Main";

export default {
  name: "Layout",
  components: {
    Header,
    Logo,
    Menu,
    Main,
  },
};
</script>

<style>
</style>
