import AUTHENTICATED_USER from "../../../graphql/queries/me.gql";
import LOGIN_USER from "../../../graphql/mutations/login.gql";
import REGISTER_USER from "../../../graphql/mutations/register.gql";
import { apolloClient } from "../../vue-apollo";
import router from "../../router";

const state = {
  user: {},
  authStatus: false,
  token: localStorage.getItem("apollo-token") || null,
  loginErrors: [],
  invalidCredentials: "",
};

const getters = {
  user: state => state.user,
  isAuth: state => !!state.token,
  authStatus: state => state.authStatus,
  loginErrors: state => state.loginErrors,
  invalidCredentials: state => state.invalidCredentials,
};

const actions = {
  // Method Login
  async loginUser({ commit, dispatch }, userData) {
    try {
      let {
        data: { login },
      } = await apolloClient.mutate({
        mutation: LOGIN_USER,
        variables: userData,
      });
      dispatch("setAuthUserData", login);
    } catch (err) {
      const errors = err.message.split(": ").slice(1, err.message.split(": ").length);
      // console.log("errors", errors);
      commit("LOGIN_ERRORS", []);
      commit("INVALID_CREDENTIALS", "");
      if (errors[0] === "invalid_client") {
        commit("INVALID_CREDENTIALS", errors[0]);
      }
      // else {
      //   const newErrors = [];Login
      //   for (const key of Object.keys(errors)) {
      //     errors[key].forEach(err => {
      //       newErrors.push(err);
      //     });
      //   }
      //   console.log("errors 2", newErrors);
      // }
    }
  },
  // Method Register
  async registerUser({ dispatch }, userData) {
    let {
      data: {
        register: { tokens },
      },
    } = await apolloClient.mutate({
      mutation: REGISTER_USER,
      variables: userData,
    });
    dispatch("setAuthUserData", tokens);
  },
  // Method Set Data for LOGIN and REGISTER
  async setAuthUserData({ commit }, payload) {
    commit("LOGIN_USER", payload);
    commit("SET_TOKEN", payload);
    // Set token in localStorage
    localStorage.setItem("apollo-token", payload.access_token);
    // redirect the user to the dashboard
    router.push("/");
  },
  // Get User
  async getAuthUser({ commit, dispatch }) {
    try {
      let {
        data: { me },
      } = await apolloClient.query({
        query: AUTHENTICATED_USER,
      });
      commit("LOGIN_USER", { user: me });
    } catch (err) {
      dispatch("logoutUser");
    }
  },
  // Method Logout
  logoutUser({ commit }) {
    commit("LOGOUT_USER");
    localStorage.removeItem("apollo-token");
  },
};

const mutations = {
  LOGIN_USER(state, payload) {
    state.user = payload.user;
    state.authStatus = true;
    state.isMessageError = "";
  },
  SET_TOKEN(state, payload) {
    state.token = payload.access_token;
  },
  LOGOUT_USER(state) {
    state.user = {};
    state.authStatus = false;
    state.token = null;
    state.isMessageError = "";
  },
  LOGIN_ERRORS: (state, payload) => {
    state.loginErrors = payload;
  },
  INVALID_CREDENTIALS: (state, payload) => {
    state.invalidCredentials = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
