import "./registerServiceWorker";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";

import App from "./App.vue";
import ElementUI from "element-ui";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";

// Sweetalert
Vue.use(VueSweetalert2);

// Vue
Vue.config.productionTip = false;

// Element UI
Vue.use(ElementUI);

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount("#app");
